<template>
  <auth-wrapper>
    <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
    <v-card-title primary-title class="justify-center">Sign up for Rentify with</v-card-title>
    <v-img src="../assets/appsumo-logo.png" height="50px" contain></v-img>
    <v-card-text class="body-2 justify-center text-center">
      Enter your details and AppSumo Code below to get started
    </v-card-text>
    <v-card-text class="mb-0 pb-0">
      <v-form v-model="valid" autocomplete="off">
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <text-field
                label="Your name"
                prepend-icon="mdi-account-circle"
                :rules="[rules.required]"
                v-model="form.name"
            >
            </text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <text-field
                label="Store name"
                prepend-icon="mdi-store"
                :rules="[rules.required]"
                v-model="form.storeName"
            >
            </text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <text-field
                label="Email"
                name="login"
                prepend-icon="mdi-email"
                :rules="[rules.required, rules.email]"
                v-model="form.username"
            ></text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <text-field
                label="Password"
                :type="showPass ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                :rules="[rules.required, rules.password]"
                v-model="form.password"
            ></text-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <text-field
                label="AppSumo Code"
                prepend-icon="mdi-store"
                :rules="[rules.required]"
                v-model="form.appsumoToken"
            >
            </text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox v-model="acceptTerms" class="ml-1" :rules="[rules.accept]">
              <template v-slot:label>
                <div>
                  I agree to the&nbsp;<a @click="openTerms" class="no-underline">terms of use</a> and
                  <a @click="openPrivacy" class="no-underline">privacy policy</a>
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>Already have a Rentify account?
      <router-link :to="form.appsumoToken ?  `/linksumo/${form.appsumoToken}`: '/linksumo'" v-slot="{ href }">
        <a :href="href">Click here to add your AppSumo code to it</a>
      </router-link>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          rounded
          outlined
          :loading="loading"
          color="primary"
          :disabled="!valid"
          @click="registerUser"
      >
        Register
      </v-btn>
    </v-card-actions>
  </auth-wrapper>
</template>


<script>
import {createSumoUser} from '@/graphql'
import {BACKEND_DOMAIN} from "../variables";
import Cookie from 'js-cookie'

export default {
  name: 'AppSumo',
  data: () => ({
    valid: null,
    form: {
      name: null,
      storeName: null,
      email: null,
      password: null,
      appsumoToken: null
    },
    showPass: false,
    acceptTerms: false,
    rules: {
      required: v => !!v || 'This field is required',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      password: v => !!v && v.length >= 6 || 'Password must be at least 6 characters long',
      accept: v => v === true || 'You must accept the terms'
    },
    loading: false,
    error: false,
    errorMessage: null
  }),
  mounted() {
    if (this.$route.params.token) {
      this.form.appsumoToken = this.$route.params.token
    }
  },
  methods: {
    registerUser() {
      return this.$apollo.mutate({
        mutation: createSumoUser,
        variables: this.form
      }).catch((error) => {
        this.error = true;
        this.errorMessage = error.message.replace('GraphQL error: ', '');
        this.loading = false
      }).then(({data}) => {
        const {token} = data.createSumoUser;
        if (BACKEND_DOMAIN) {
          Cookie.set('JWT', token, {domain: BACKEND_DOMAIN});
        } else {
          Cookie.set('JWT', token);
        }
        window.location.reload()
        this.loading = false;
      })
    },
    openTerms() {
      window.location = 'https://assets.rentify.store/terms.html'
    },
    openPrivacy() {
      window.location = 'https://assets.rentify.store/privacy.html'
    },
  }

}
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}

</style>
